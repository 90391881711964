export const KPIList = [
    'aos_percentage',
    'blanks_pc9',
    'blanks_total',
    'conversion_percentage',
    'e_receipt_capture',
    'weeks_of_coverage',
    'transaction_count',
    'traffic',
    'store_count_traffic',
    'store_count_sales',
    'sales_vs_traffic_with_tax',
    'sales_vs_traffic',
    'sales_units_net',
    'sales_units_gross',
    'sales_over_traffic_with_tax',
    'sales_over_traffic',
    'salesfloor_instock',
    'retail_sales_returns_value_with_tax',
    'retail_sales_returns_value',
    'retail_sales_returns_percentage_with_tax',
    'retail_sales_returns_percentage',
    'retail_sales_return_units',
    'retail_sales_net_value_with_tax',
    'retail_sales_net_margin_percentage',
    'retail_sales_net_aur_with_tax',
    'retail_sales_net_atv_with_tax',
    'retail_sales_net_value',
    'retail_sales_net_upt',
    'retail_sales_net_margin',
    'retail_sales_net_aur',
    'retail_sales_net_atv',
    'retail_sales_gross_value_with_tax',
    'retail_sales_gross_value',
    'net_sales_with_tax_by_gross_leased_area',
    'net_sales_by_gross_leased_area',
    'ioh_value_eop',
    'ioh_units_eop',
    'adoption_percent',
  ];
  
  export const KPIDefinitionList = {
    aos_percentage: {
      kpi: 'AOS Percentage',
      definition:
        '[BackPocket AOS Sales/Demand with Tax] / [Instore Sales with Tax + BackPocket AOS Sales/Demand with Tax]',
    },
    blanks_pc9: {
      kpi: 'Blanks PC9',
      definition:
        'Products with zero sellout, in total PC9s (options). Count of of Blanks PC9s/Total number of PC9 for that store',
    },
    blanks_total: {
      kpi: 'Blanks Total',
      definition:
        'Products with zero sellout, in total from Full Stock. On-hand Inventory of Blanks PC9/On-hand Inventory of Total PC9 for that store',
    },
    conversion_percentage: {
      kpi: 'Conversion Percentage',
      definition: '[Transactions] / [Traffic ]',
    },
    e_receipt_capture: {
      kpi: 'E Receipt Capture',
      definition:
        '# of Transactions where customer provides their e-mail address for receipts / Total # of Transactions',
    },
    weeks_of_coverage: {
      kpi: 'Weeks Of Coverage',
      definition: "[IOH Units EoP] / prior week's net sales units",
    },
    transaction_count: {
      kpi: 'Transaction Count',
      definition: 'Count of all transactions (sales and returns in stores)',
    },
    traffic: {
      kpi: 'Traffic',
      definition: 'Count of customers who walked into a store',
    },
    store_count_traffic: {
      kpi: 'Store Count Traffic',
      definition:
        'The number of stores that the dashboard is displaying traffic for (based on filter selection)',
    },
    store_count_sales: {
      kpi: 'Store Count Sales',
      definition:
        'The number of stores that the dashboard is displaying sales for (based on filter selection)',
    },
    sales_vs_traffic_with_tax: {
      kpi: 'Sales Vs Traffic With Tax',
      definition:
        '[Retail Sales Net – Value with Tax] Variance to Prior Year as a % minus Traffic Variance to Prior Year as a %',
    },
    sales_vs_traffic: {
      kpi: 'Sales vs Traffic',
      definition:
        '[Retail Sales Net – Value ] Variance to Prior Year as a % minus Traffic Variance to Prior Year as a %',
    },
    sales_units_net: {
      kpi: 'Sales Units Net',
      definition: 'Total units sold after returns',
    },
    sales_units_gross: {
      kpi: 'Sales Units Gross',
      definition: 'Total units sold',
    },
    sales_over_traffic_with_tax: {
      kpi: 'Sales Over Traffic With Tax',
      definition: '[Retail Sales Net – Value with Tax] / Traffic',
    },
    sales_over_traffic: {
      kpi: 'Sales Over Traffic',
      definition: '[Retail Sales Net – Value] / Traffic',
    },
    salesfloor_instock: {
      kpi: 'Salesfloor Instock',
      definition:
        'Percentage of sizes, by style, represented on the salesfloor compared to what sizes the store owns accordingly to the most recent full store scan',
    },
    retail_sales_returns_value_with_tax: {
      kpi: 'Retail Sales Returns Value With Tax',
      definition:
        'Value of items returned by customer net of any discounts; including VAT or other tax',
    },
    retail_sales_returns_value: {
      kpi: 'Retail Sales Returns Value',
      definition:
        'Value of items returned by customer net of any discounts; not including VAT or other tax',
    },
    retail_sales_returns_percentage_with_tax: {
      kpi: 'Retail Sales Returns Percentage With Tax',
      definition:
        '[Retail Sales –Return with Tax] / [Retail Sales – Gross with Tax]',
    },
    retail_sales_returns_percentage: {
      kpi: 'Retail Sales Returns Percentage',
      definition: '[Retail Sales - Return] / [Retail Sales - Gross]',
    },
    retail_sales_return_units: {
      kpi: 'Retail Sales Return Units',
      definition: 'Total units returned',
    },
    retail_sales_net_value_with_tax: {
      kpi: 'Retail Sales Net Value With Tax',
      definition:
        '[Retail Sales Gross – Value with Tax] - [Retail Sales Returns – Value with Tax]',
    },
    retail_sales_net_margin_percentage: {
      kpi: 'Retail Sales Net Margin Percentage',
      definition: '[Retail Sales Net - Margin] / [Retail Sales Net - Value]',
    },
    retail_sales_net_aur_with_tax: {
      kpi: 'Retail Sales Net Aur With Tax',
      definition:
        '[Retail Sales Net – Value with Tax] / [Retail Sales Net - Units ]',
    },
    retail_sales_net_atv_with_tax: {
      kpi: 'Retail Sales Net Atv With Tax',
      definition: '[Retail Sales Net – Value with Tax] / [Transactions]',
    },
    retail_sales_net_value: {
      kpi: 'Retail Sales Net Value',
      definition: '[Retail Sales Gross – Value] - [Retail Sales Returns - Value]',
    },
    retail_sales_net_upt: {
      kpi: 'Retail Sales Net UPT',
      definition: '[Retail Sales Net - Units] / [Transactions]',
    },
    retail_sales_net_margin: {
      kpi: 'Retail Sales Net Margin',
      definition: 'Retail Sales Net - Value - management COGS',
    },
    retail_sales_net_aur: {
      kpi: 'Retail Sales Net AUR',
      definition: '[Retail Sales Net – Value] / [Retail Sales Net - Units ]',
    },
    retail_sales_net_atv: {
      kpi: 'Retail Sales Net ATV',
      definition: '[Retail Sales Net – Value] / [Transactions]',
    },
    retail_sales_gross_value_with_tax: {
      kpi: 'Retail Sales Gross Value With Tax',
      definition:
        'Sales before returns; net of any discounts; including VAT or other tax',
    },
    retail_sales_gross_value: {
      kpi: 'Retail Sales Gross Value',
      definition:
        'Sales before returns; net of any discounts; not including VAT or other tax',
    },
    net_sales_with_tax_by_gross_leased_area: {
      kpi: 'Net Sales With Tax By Gross Leased Area',
      definition: '[Retail Sales Net – Value with Tax] / Total Store Size',
    },
    net_sales_by_gross_leased_area: {
      kpi: 'Net Sales By Gross Leased Area',
      definition: '[Retail Sales Net – Value] / Total Store Size',
    },
    ioh_value_eop: {
      kpi: 'IOH Value EOP',
      definition:
        'Value of inventory (at management COGS) available at end of period',
    },
    ioh_units_eop: {
      kpi: 'IOH Units EOP',
      definition: 'Number of units of inventory available at end of period',
    },
    adoption_percent: {
      kpi: 'Adoption Percent',
      definition:
        'Adoption % = (No Pick + Replen Units) / Refill Quantity. Description: % of the Replen Recommendations have been actioned by the Store',
    },
  };