import { transformStocks } from '../utils/stocksTransforms';
import { getKpis, useData, getStockData } from '../../api';
import { useMemo } from 'react';
import { attachAndFormatTimeStamp } from '../utils/transforms';
import { useHandleNotGeneratedPeriod } from './useHandleNotGeneratedPeriod';

export default function useStockData(
  locationId,
  locationParams,
  periodId,
  useUsdAmount,
  includeTaxes,
  useLLY
) {
  const kpis = useData(getKpis, {}, 'kpis');
  const stocks = useData(
    getStockData,
    {
      period: periodId,
      location: locationId,
      ...locationParams,
    },
    'sales'
  );

  useHandleNotGeneratedPeriod({ response: stocks, status: 0 });

  // ensure reference equality for consumer components by using useMemo hook
  let mappedData = useMemo(() => {
    if (stocks.data && kpis.data) {
      const { sales_and_inventory, asOf } = stocks.data;

      let formattedData = {};
      Object.keys(sales_and_inventory).forEach(salesType => {
        const transformedData = transformStocks(
          { sales_and_inventory: sales_and_inventory[salesType] },
          kpis.data,
          useUsdAmount,
          includeTaxes,
          periodId,
          useLLY
        );

        formattedData[salesType] = transformedData;
      });

      // TODO: (actual API) It's advised that the `asOf` be at the root of the response
      attachAndFormatTimeStamp(formattedData, asOf);
      return formattedData;
    }
    return null;
  }, [stocks.data, kpis.data, useUsdAmount, includeTaxes, periodId, useLLY]);

  return {
    data: mappedData,
    loading: kpis.loading || stocks.loading,
    error: kpis.error || stocks.error,
    refetch: () => {
      if (kpis.error) kpis.refetch();
      if (stocks.error) stocks.refetch();
    },
  };
}
