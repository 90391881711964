import PropTypes from 'prop-types';
import React from 'react';

import { AddKPICard, CardGrid, CompareCard } from '../../components/Cards';
import CompareList from '../../components/CompareList';
import ErrorMessage from '../../components/Errors/ErrorMessage';
import ErrorPageOrChildren from '../../components/Errors/ErrorPageOrChildren';
import ErrorBoundary from '../../ErrorBoundary';
import LoadingBar from '../../components/LoadingBar';
import TimeStampPanel from '../../components/TimeStampPanel';
import ProductCompare from '../../components/ProductCompare';
import useCompareItemsData from '../../data/hooks/useCompareItemsData';
import {
  formatLocationAndParams,
  LocationAndParamsShape,
} from '../../location';
import { PageView } from '../../gtag';
import { Note } from '../../typography';
import {listOfKPIs} from "../../data";

const CompareListContainer = ({
  data,
  revenueKPIsData,
  sfsRevenueData,
  bopisRevenueData,
  loading,
  error,
  refetch,
  period,
  locationAndParamsA,
  locationAndParamsB,
  useLLY,
  useUsdAmount,
  userSettings,
}) => {
  if (loading) {
    return <LoadingBar />;
  }

  return (
    <ErrorPageOrChildren
      errors={[error]}
      message={error?.message}
      onClickRetry={() => refetch()}
    >
      <ErrorBoundary>
        {data && (
          <PageView
            params={{
              period_id: period.id,
              compare_location_id_1: locationAndParamsA.location.name,
              compare_location_id_2: locationAndParamsB.location.name,
              compare_channel_1: locationAndParamsA.params.channel,
              compare_channel_2: locationAndParamsB.params.channel,
            }}
          >
            <CompareList
              items={data}
              revenueKPIsData={revenueKPIsData}
              sfsRevenueData={sfsRevenueData}
              bopisRevenueData={bopisRevenueData}
              useLLY={useLLY}
              useUsdAmount={useUsdAmount}
            />
            <ProductCompare
              period={period}
              locationA={locationAndParamsA}
              locationB={locationAndParamsB}
              userSettings={userSettings}
            />
          </PageView>
        )}
      </ErrorBoundary>
    </ErrorPageOrChildren>
  );
};

const WithCompareItemsData = ({
  cardGrid,
  locationAndParamsA,
  locationAndParamsB,
  period,
  userSettings,
  showTimeStamp,
}) => {
  const { useUsdAmount, includeTaxes, useLLY } = userSettings;
  const response = useCompareItemsData(
    period.id,
    locationAndParamsA,
    locationAndParamsB,
    useUsdAmount,
    includeTaxes,
    useLLY,
    period.periods_trends
  );
  const { data } = response;
  const salesBucketValue = 'IN_STORE_SALES';
  const responseData = data?.[salesBucketValue] || [];

  // Filter for revenue KPIs
  const revenueKPIs = responseData
    .filter(metric => {
      return (
        metric.id &&
        listOfKPIs.some(excludeKPIs => metric.id.includes(excludeKPIs))
      );
    })
    .sort((a, b) => {
      if (a.id.includes('aos') && !b.id.includes('aos')) {
        return -1;
      }
      if (!a.id.includes('aos') && b.id.includes('aos')) {
        return 1;
      }
      return 0;
    });

  const sfsSalesValue = 'SFS_SALES';
  const sfsData = data?.[sfsSalesValue] || [];
  const sfsRevenue = sfsData
    .filter(metric => metric.id && metric.id.includes('retail_sales_net_value'))
    .map(metric => {
      metric.id = 'sfs_sales';
      metric.label = 'SFS Sales Value';
      return metric;
    });

  const bopisSalesValue = 'BOPIS_SALES';
  const dataBOPIS = data?.[bopisSalesValue] || [];
  const bopisRevenue = dataBOPIS
    ?.filter(
      metric => metric.id && metric.id.includes('retail_sales_net_value')
    )
    .map(metric => {
      metric.id = 'bopis_sales';
      metric.label = 'BOPIS Sales Value';
      return metric;
    });
  return (
    <>
      {data?.formattedTimeStamp && showTimeStamp && (
        <TimeStampPanel formattedValue={data.formattedTimeStamp} />
      )}
      {cardGrid}
      <CompareListContainer
        {...response}
        data={responseData}
        revenueKPIsData={revenueKPIs}
        sfsRevenueData={sfsRevenue}
        bopisRevenueData={bopisRevenue}
        period={period}
        locationAndParamsA={locationAndParamsA}
        locationAndParamsB={locationAndParamsB}
        useLLY={useLLY}
        useUsdAmount={useUsdAmount}
        userSettings={userSettings}
      />
    </>
  );
};

const LocationAndParamsCardContent = ({ locationAndParams }) => {
  const { location: formattedLocation, params: formattedParams } =
    formatLocationAndParams(locationAndParams);

  return (
    <div>
      {formattedLocation}
      {formattedParams && (
        <Note gray style={{ marginTop: 2 }}>
          {formattedParams}
        </Note>
      )}
    </div>
  );
};

const Comparison = ({
  period,
  priorDayPeriod,
  locationA: locationAndParamsA,
  locationB: locationAndParamsB,
  locationAOnClick,
  locationBOnClick,
  userSettings,
  showTimeStamp,
}) => {
  const selectedPeriod = period?.id === "timeframe" ? priorDayPeriod : period;
  const allColumnsSelected = Boolean(locationAndParamsA && locationAndParamsB);
  const cardGrid = (
    <CardGrid>
      {locationAndParamsA ? (
        <CompareCard color="primary" onClick={() => locationAOnClick()}>
          <LocationAndParamsCardContent
            locationAndParams={locationAndParamsA}
          />
        </CompareCard>
      ) : (
        <AddKPICard onClick={() => locationAOnClick()} />
      )}
      {locationAndParamsB ? (
        <CompareCard color="secondary" onClick={() => locationBOnClick()}>
          <LocationAndParamsCardContent
            locationAndParams={locationAndParamsB}
          />
        </CompareCard>
      ) : (
        <AddKPICard onClick={() => locationBOnClick()} />
      )}
    </CardGrid>
  );

  return allColumnsSelected ? (
    <WithCompareItemsData
      cardGrid={cardGrid}
      locationAndParamsA={locationAndParamsA}
      locationAndParamsB={locationAndParamsB}
      period={selectedPeriod}
      userSettings={userSettings}
      showTimeStamp={showTimeStamp}
    />
  ) : (
    <>
      {cardGrid}
      <ErrorMessage
        message={
          <>
            <br />
            Select a second store to compare
          </>
        }
      />
    </>
  );
};

Comparison.propTypes = {
  period: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  locationA: LocationAndParamsShape.isRequired,
  locationB: LocationAndParamsShape,
  userSettings: PropTypes.shape({
    useUsdAmount: PropTypes.bool.isRequired,
    includeTaxes: PropTypes.bool.isRequired,
  }),
};

const MemoComparison = React.memo(
  Comparison,
  (prevProps, nextProps) =>
    prevProps.locationA === nextProps.locationA &&
    prevProps.locationB === nextProps.locationB &&
    prevProps.period === nextProps.period &&
    prevProps.userSettings === nextProps.userSettings
);

export default MemoComparison;
